<template>
  <div>
    <Navbar></Navbar>
    <div class="container-fluid p-0">
      <div class="row">
        <Side_menu></Side_menu>
        <div class="col">
          <div class="buttons_create">
            <div>
              <h3 class="font-weight-bold mb-3">Прозрачность бюджет</h3>
            </div>
          </div>
          <div class="main_card opendata__create">
            <b-card no-body>
        <div class="row">
          <div class="col-md-12">
            <b-tabs card>
              <b-tab title="Uzbek lotin"  active>

                <b-card-text>
                  <form @submit.prevent="put_uzl()">

                    <div class="row">
                      <div class="col-md-12  mb-3">
                        
                                <label>Название отчета</label>
                                <input class="form-control" v-model="name_uz" type="text"  required>
                        <div class="create_link mb-3" v-for="(item,index) in selected" :key="index">
                            <div>
                                <label>Название файла</label>
                                <input class="form-control" v-model="item.title" :key="index" type="text"  required>
                            </div>
                            <div>
                                <label>Файл отчета</label><br>
                                <input @change="handleUpload($event,index)" type="file"  required>
                                <i class="close_x" @click="remove(index)">&#x2715;</i>
                            </div>
                        </div>
                        <button class="btn" @click="add">+ Добавить</button>
                      </div>


                    </div>
                    <div class="mt-3">
                      <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
              <b-tab title="Rus" >
                <b-card-text>
                  <form @submit.prevent="post_ru()">

                    <div class="row">
                      <div class="col-md-12  mb-3">
                        
                                <label>Название отчета</label>
                                <input class="form-control" v-model="name_ru" type="text"  required>
                        <div class="create_link mb-3" v-for="(item,index) in selected_ru" :key="index">
                            <div>
                                <label for="first_name">Название файла</label>
                                <input class="form-control" id="first_namee" v-model="item.title" :key="index" type="text"  required>
                            </div>
                            <div>
                                <label>Файл отчета</label><br>
                                <input @change="handleUpload_ru($event,index)" type="file"  required>
                                <i class="close_x" @click="remove_ru(index)">&#x2715;</i>
                            </div>
                        </div>
                        <button class="btn" @click="add_ru">+ Добавить</button>
                      </div>
                    </div>
                    <div class="mt-3">
                      <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
              <b-tab title="Uzbek kyrl">

                <b-card-text>
                  <form @submit.prevent="put_uzk()">

                    <div class="row">
                      <div class="col-md-12  mb-3">
                        
                                <label>Название отчета</label>
                                <input class="form-control" v-model="name_uz_kril" type="text"  required>
                        <div class="create_link mb-3" v-for="(item,index) in selected_kril" :key="index">
                            <div>
                                <label for="first_name">Название файла</label>
                                <input class="form-control" id="first_namee" v-model="item.title" :key="index" type="text"  required>
                            </div>
                            <div>
                                <label for="first_namee_link">Файл отчета</label><br>
                                <input @change="handleUpload_kril($event,index)" type="file"  required>
                                <i class="close_x" @click="remove_kril(index)">&#x2715;</i>
                            </div>
                        </div>
                        <button class="btn" @click="add_kril">+ Добавить</button>
                      </div>


                    </div>
                    <div class="mt-3">
                      <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
              <b-tab title="English">

                <b-card-text>
                  <form @submit.prevent="put_en()">

                    <div class="row">
                      <div class="col-md-12  mb-3">
                        
                                <label>Название отчета</label>
                                <input class="form-control" v-model="name_en" type="text"  required>
                        <div class="create_link mb-3" v-for="(item,index) in selected_en" :key="index">
                            <div>
                                <label >Название файла</label>
                                <input class="form-control"  v-model="item.title" :key="index" type="text"  required>
                            </div>
                            <div>
                                <label for="first_namee_link">Файл отчета</label><br>
                                <input @change="handleUpload_en($event,index)" type="file"  required>
                                <i class="close_x" @click="remove_en(index)">&#x2715;</i>
                            </div>
                        </div>
                        <button class="btn" @click="add_en">+ Добавить</button>
                      </div>
                      <!-- <div class="col-md-12  mb-3">
                        <div>
                          <label for="first_name">initial_published_date</label>
                          <input class="form-control" id="first_namee" v-model="initial_published_date4" type="date"  required>
                        </div>
                      </div>
                      <div class="col-md-12  mb-3">
                        <div>
                          <label for="first_name">last_published_date</label>
                          <input class="form-control" id="first_namee" v-model="last_published_date4" type="date"  required>
                        </div>
                      </div> -->

                    </div>
                    <div class="mt-3">
                      <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>

        </div>
      </b-card>
          </div>
  </div>
      </div>
    </div>
  </div>
</template>
<script>
import Side_menu from '../../components/Side_menu'
import Navbar from "../../components/Navbar";
export default {
  data(){
    return{
      name_uz: '',
      name_ru:"",
      name_uz_kril: "",
      name_en:"",
      selected:[
        {
            title:"",
            file: null,
        }
      ],
      form: {
            title:"",
            file: null,
        },
      selected_ru:[
        {
            title:"",
            file: null,
        }
      ],
      form_ru: {
            title:"",
            file: null,
        },
      selected_kril:[
        {
            title:"",
            file: null,
        }
      ],
      form_kril: {
            title:"",
            file: null,
        },
      selected_en:[
        {
            title:"",
            file: null,
        }
      ],
      form_en: {
            title:"",
            file: null,
        }
    }
  },
  components: {
    Navbar,
    Side_menu
  },
  methods:{
     add(){
        this.selected.push({...this.form})
    },
    add_ru(){
        this.selected_ru.push({...this.form_ru})
    },
    add_kril(){
        this.selected_kril.push({...this.form_kril})
    },
    add_en(){
        this.selected_en.push({...this.form_en})
    },
    remove(e){
        this.selected.splice(e, 1);
    },
    remove_kril(e){
        this.selected_kril.splice(e, 1);
    },
    remove_ru(e){
        this.selected_ru.splice(e, 1);
    },
    remove_en(e){
        this.selected_en.splice(e, 1);
    },
    handleUpload(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected[index].file = e.target.files[0]
        }
    },
    handleUpload_ru(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_ru[index].file = e.target.files[0]
        }
    },
    handleUpload_kril(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_kril[index].file = e.target.files[0]
        }
    },
    handleUpload_en(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_en[index].file = e.target.files[0]
        }
    },
    post_ru(){

      const form = new FormData();
      for(var i=0;i<this.selected_ru.length;i++){
           let {file} = this.selected_ru[i]
        form.append("files", file)
        }
        form.append("name",this.name_ru)
        for(let i=0;i<this.selected_ru.length;i++){
          let {title} = this.selected_ru[i]
          form.append("title", title);
        }
      this.$http.post('https://opendata.bostonliqlive.uz/api/transparency-budget/',
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'ru'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
            this.document_number='',
                this.published_date='',
                this.selectitem=''
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_uzl(){

      const form = new FormData();
      for(var i=0;i<this.selected.length;i++){
           let {file} = this.selected[i]
        form.append("files", file)
        }
        form.append("name",this.name_uz)
        for(let i=0;i<this.selected.length;i++){
          let {title} = this.selected[i]
          form.append("title", title);
        }
      this.$http.post('https://opendata.bostonliqlive.uz/api/transparency-budget/',
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'uz-latn'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
            this.document_number='',
                this.published_date='',
                this.selectitem=''
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_uzk(){

      const form = new FormData();
      for(var i=0;i<this.selected_kril.length;i++){
           let {file} = this.selected_kril[i]
        form.append("files", file)
        }
        form.append("name",this.name_uz_kril)
        for(let i=0;i<this.selected_kril.length;i++){
          let {title} = this.selected_kril[i]
          form.append("title", title);
        }
      this.$http.post('https://opendata.bostonliqlive.uz/api/transparency-budget/',
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'uz-cyrl'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
            this.document_number='',
                this.published_date='',
                this.selectitem=''
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_en(){

      const form = new FormData();
      for(var i=0;i<this.selected_en.length;i++){
           let {file} = this.selected_en[i]
        form.append("files", file)
        }
        form.append("name",this.name_en)
        for(let i=0;i<this.selected_en.length;i++){
          let {title} = this.selected_en[i]
          form.append("title", title);
        }
      this.$http.post('https://opendata.bostonliqlive.uz/api/transparency-budget/',
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'en'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
            this.document_number='',
                this.published_date='',
                this.selectitem=''
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
  }
}
</script>
