<template>
  <div>
    <Navbar></Navbar>
    <div class="container-fluid p-0">
      <div class="row">
        <Side_menu></Side_menu>
        <div class="col">
          <div class="buttons_create">
            <div>
              <h3 class="font-weight-bold mb-3">Открытые данные</h3>
            </div>
          </div>
    <div class="main_card opendata__create">
      <Create />
    </div>
  </div>
      </div>
    </div>
  </div>
</template>
<script>
import Side_menu from '../../components/Side_menu'
import Navbar from "../../components/Navbar";
import Create from "../../components/opendata/create.vue";
export default {
  data(){
    return{
      create: 'create1'
    }
  },
  components: {
    Navbar,
    Create,
    Side_menu
  },
}
</script>
