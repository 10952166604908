<template>
    <div>
        <Navbar></Navbar>
    <div class="container-fluid p-0">
      <div class="row">
        <Side_menu></Side_menu>
        <div class="col">
          <div class="buttons_create">
            <div>
              <h3 class="font-weight-bold mb-3">Прозрачность бюджет</h3>
            </div>
          </div>
          <div class=" main_card">
            <b-card no-body>
              <div class="row">
                <div class="col-md-12">
                  <b-tabs card>
                    <b-tab title="Uzbek lotin"  active>

                      <b-card-text>
                        <div>

                          <div class="row">
                            <div class="col-md-12  mb-3">
                              <label>Название отчета</label>
                                <input class="form-control" v-model="name_uz" type="text"  required>
                              <div class="create_link mb-3" v-for="(item,index) in selected" :key="index">
                                  <div>
                                      <label>Название файла</label>
                                      <input class="form-control" v-model="item.title" :key="index" type="text" required>
                                  </div>
                                  <div>
                                      <label>Файл отчета</label><br>

                                      <div class="d-flex w-100 gap-1">
                                         <label v-if="(typeof item.file) == 'string'" class="label_btn" for="first_namee_link1">Выберите файл</label><br>
                                         <input v-show="(typeof item.file) != 'string'" id="first_namee_link1" @change="handleUpload($event,index)" type="file" required>
                                         <span class="file_title" v-if="(typeof item.file) == 'string'">{{item.fileName}}</span>
                                         <i class="close_x" @click="remove(index)">&#x2715;</i>
                                      </div>
                                  </div>
                              </div>
                              <button class="btn" @click="add">+ Добавить</button>
                            </div>


                          </div>
                          <div class="mt-3">
                            <button  @click="put_uzl()" class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Rus" >
                      <b-card-text>
                        <div>

                          <div class="row">
                            <div class="col-md-12  mb-3">
                              <label>Название отчета</label>
                                <input class="form-control" v-model="name_ru" type="text"  required>
                              <div class="create_link mb-3" v-for="(item,index) in selected_ru" :key="index">
                                  <div>
                                      <label for="first_name">Название файла</label>
                                      <input class="form-control" id="first_namee" v-model="item.title" :key="index" type="text" required>
                                  </div>
                                  <div>
                                      <label>Файл отчета</label><br>

                                      <div class="d-flex w-100 gap-1">
                                         <label v-if="(typeof item.file) == 'string'" class="label_btn" for="first_namee_link1">Выберите файл</label><br>
                                         <input v-show="(typeof item.file) != 'string'" id="first_namee_link1" @change="handleUpload_ru($event,index)" type="file" required>
                                         <span class="file_title" v-if="(typeof item.file) == 'string'">{{item.fileName}}</span>
                                         <i class="close_x" @click="remove_ru(index)">&#x2715;</i>
                                      </div>
                                  </div>
                              </div>
                              <button class="btn" @click="add_ru">+ Добавить</button>
                            </div>
                          </div>
                          <div class="mt-3">
                            <button @click="post_ru" class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Uzbek kyrl">

                      <b-card-text>
                        <div>

                          <div class="row">
                            <div class="col-md-12  mb-3">
                              <label>Название отчета</label>
                                <input class="form-control" v-model="name_uz_kril" type="text"  required>
                              <div class="create_link mb-3" v-for="(item,index) in selected_kril" :key="index">
                                  <div>
                                      <label for="first_name">Название файла</label>
                                      <input class="form-control" id="first_namee" v-model="item.title" :key="index" type="text"  required>
                                  </div>
                                  <div>
                                      <label>Файл отчета</label><br>
                                      
                                      <div class="d-flex w-100 gap-1">
                                         <label v-if="(typeof item.file) == 'string'" class="label_btn" for="first_namee_link1">Выберите файл</label><br>
                                         <input v-show="(typeof item.file) != 'string'" id="first_namee_link1" @change="handleUpload_kril($event,index)" type="file" required>
                                         <span class="file_title" v-if="(typeof item.file) == 'string'">{{item.fileName}}</span>
                                         <i class="close_x" @click="remove_kril(index)">&#x2715;</i>
                                      </div>
                                  </div>
                              </div>
                              <button class="btn" @click="add_kril">+ Добавить</button>
                            </div>


                          </div>
                          <div class="mt-3">
                            <button @click="put_uzk" class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="English">

                      <b-card-text>
                        <div>

                          <div class="row">
                            <div class="col-md-12  mb-3">
                              <label>Название отчета</label>
                                <input class="form-control" v-model="name_en" type="text"  required>
                              <div class="create_link mb-3" v-for="(item,index) in selected_en" :key="index">
                                  <div>
                                      <label >Название файла</label>
                                      <input class="form-control"  v-model="item.title" :key="index" type="text" required>
                                  </div>
                                  <div>
                                      <label>Файл отчета</label><br>
                                      <div class="d-flex w-100 gap-1">
                                         <label v-if="(typeof item.file) == 'string'" class="label_btn" for="first_namee_link">Выберите файл</label><br>
                                         <input v-show="(typeof item.file) != 'string'" id="first_namee_link" @change="handleUpload_en($event,index)" type="file" required>
                                         <span class="file_title" v-if="(typeof item.file) == 'string'">{{item.fileName}}</span>
                                         <i class="close_x" @click="remove_en(index)">&#x2715;</i>
                                      </div>
                                  </div>
                              </div>
                              <button class="btn" @click="add_en">+ Добавить</button>
                            </div>
                            <!-- <div class="col-md-12  mb-3">
                              <div>
                                <label for="first_name">initial_published_date</label>
                                <input class="form-control" id="first_namee" v-model="initial_published_date4" type="date"  required>
                              </div>
                            </div>
                            <div class="col-md-12  mb-3">
                              <div>
                                <label for="first_name">last_published_date</label>
                                <input class="form-control" id="first_namee" v-model="last_published_date4" type="date"  required>
                              </div>
                            </div> -->

                          </div>
                          <div class="mt-3">
                            <button @click="put_en" class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </div>

              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import Side_menu from '../../components/Side_menu'
import Navbar from "../../components/Navbar";
export default {
  components: {
    Navbar,
    Side_menu
  },
    
  data(){
    return{
      name_uz: "",
      name_ru:"",
      name_uz_kril: "",
      name_en:"",
      selected:[
        {
            title:"",
            file: null,
        }
      ],
      form: {
            title:"",
            file: null,
        },
      selected_ru:[
        {
            title:"",
            file: null,
        }
      ],
      form_ru: {
            title:"",
            file: null,
        },
      selected_kril:[
        {
            title:"",
            file: null,
        }
      ],
      form_kril: {
            title:"",
            file: null,
        },
      selected_en:[
        {
            title:"",
            file: null,
        }
      ],
      form_en: {
            title:"",
            file: null,
        },
        oldDocuments: [],
        link:'https://opendata.bostonliqlive.uz'


    }
  },
  mounted() {
     this.$http.get(this.link+'/api/transparency-budget/'+this.$route.query.id+'/',{
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('m_token'),
        'Accept-Language':'uz-latn'
      }
    })
        .then(response=>{
          let resRu = response.data && response.data.ru && JSON.parse(response.data.ru)
          let resUz = response.data && response.data.uzLatin && JSON.parse(response.data.uzLatin)
          let resCr = response.data && response.data.uz && JSON.parse(response.data.uz)
          let resEn = response.data && response.data.en && JSON.parse(response.data.en)
            this.selected=resUz?.documents?resUz?.documents:[{title:"",file: null,}]
          this.name_uz=resUz?.name
            this.selected_kril=resCr?.documents?resCr?.documents:[{title:"",file: null,}]
          this.name_uz_kril=resCr?.name
            this.selected_en=resEn?.documents?resEn?.documents:[{title:"",file: null,}]
          this.name_en=resEn?.name
            this.selected_ru=resRu?.documents?resRu?.documents:[{title:"",file: null,}]
          this.name_ru=resRu?.name
        })
  },
  methods:{

    add(){
        this.selected.push({...this.form})
    },
    add_ru(){
        this.selected_ru.push({...this.form_ru})
    },
    add_kril(){
        this.selected_kril.push({...this.form_kril})
    },
    add_en(){
        this.selected_en.push({...this.form_en})
    },
    remove(e){
        this.selected.splice(e, 1);
    },
    remove_kril(e){
        this.selected_kril.splice(e, 1);
    },
    remove_ru(e){
        this.selected_ru.splice(e, 1);
    },
    remove_en(e){
        this.selected_en.splice(e, 1);
    },
    handleUpload(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected[index].file = e.target.files[0]
        }
    },
    handleUpload_ru(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_ru[index].file = e.target.files[0]
        }
    },
    handleUpload_kril(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_kril[index].file = e.target.files[0]
        }
    },
    handleUpload_en(e,index){
        if (e.target.files && e.target.files[0]) {
            this.selected_en[index].file = e.target.files[0]
        }
    },
    post_ru(){
       for(var k=0;k<this.selected_ru.length;k++){
        if(!this.selected_ru[k].title || !this.selected_ru[k].file){
          this.$toasted.error("Пожалуйста, заполните")
          return
        }
       }
      const form = new FormData();
      for(var i=0;i<this.selected_ru.length;i++){
        if(typeof this.selected_ru[i].file != 'string'){
             let {file} = this.selected_ru[i]
            form.append("files", file)
            
           }else{
            if(this.selected_ru.length) this.oldDocuments.push(this.selected_ru[i])
           }
        }
        form.append("name",this.name_ru)
        for(let i=0;i<this.selected_ru.length;i++){
          if(typeof this.selected_ru[i].file != 'string'){
          let {title} = this.selected_ru[i]
          form.append("title", title);
          }
        }
        form.append("oldDocuments",(JSON.stringify(this.oldDocuments)))
      this.$http.put(this.link+'/api/transparency-budget/'+this.$route.query.id,
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'ru'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            if(res) this.$router.go()
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_uzl(){
      
       for(var k=0;k<this.selected.length;k++){
        if(!this.selected[k].title || !this.selected[k].file){
          this.$toasted.error("Пожалуйста, заполните")
          return
        }
       }
      const form = new FormData();
      for(var i=0;i<this.selected.length;i++){
        if(typeof this.selected[i].file != 'string'){
             let {file} = this.selected[i]
            form.append("files", file)
            
           }else{
            if(this.selected.length)  this.oldDocuments.push(this.selected[i])
           }
        }
        form.append("name",this.name_uz)
        for(let i=0;i<this.selected.length;i++){
          if(typeof this.selected[i].file != 'string'){
          let {title} = this.selected[i]
          form.append("title", title);
          }
        }
        form.append("oldDocuments",(JSON.stringify(this.oldDocuments)))
      this.$http.put(this.link+'/api/transparency-budget/'+this.$route.query.id,
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'uz-latn'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            if(res) this.$router.go()
            
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_uzk(){
      
       for(var k=0;k<this.selected_kril.length;k++){
        if(!this.selected_kril[k].title || !this.selected_kril[k].file){
          this.$toasted.error("Пожалуйста, заполните")
          return
        }
       }

      const form = new FormData();
      for(var i=0;i<this.selected_kril.length;i++){
        if(typeof this.selected_kril[i].file != 'string'){
             let {file} = this.selected_kril[i]
            form.append("files", file)
            
           }else{
            if(this.selected_kril.length)  this.oldDocuments.push(this.selected_kril[i])
           }
        }
        form.append("name",this.name_uz_kril)
        for(let i=0;i<this.selected_kril.length;i++){
          if(typeof this.selected_kril[i].file != 'string'){
          let {title} = this.selected_kril[i]
          form.append("title", title);
          }
        }
        form.append("oldDocuments",(JSON.stringify(this.oldDocuments)))
      this.$http.put(this.link+'/api/transparency-budget/'+this.$route.query.id,
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'uz-cyrl'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            if(res) this.$router.go()
            
          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
    put_en(){
      
       for(var k=0;k<this.selected_en.length;k++){
        if(!this.selected_en[k].title || !this.selected_en[k].file){
          this.$toasted.error("Пожалуйста, заполните")
          return
        }
       }

      const form = new FormData();
      for(var i=0;i<this.selected_en.length;i++){
        if(typeof this.selected_en[i].file != 'string'){
             let {file} = this.selected_en[i]
            form.append("files", file)
            
           }else{
            if(this.selected_en.length)  this.oldDocuments.push(this.selected_en[i])
           }
        }
        form.append("name",this.name_en)
        for(let i=0;i<this.selected_en.length;i++){
          if(typeof this.selected_en[i].file != 'string'){
          let {title} = this.selected_en[i]
          form.append("title", title);
          }
        }
        form.append("oldDocuments",(JSON.stringify(this.oldDocuments)))
      this.$http.put(this.link+'/api/transparency-budget/'+this.$route.query.id,
          form,
          {
            headers: {
              'Authorization': localStorage.getItem('m_token'),
              'Accept-Language':'en'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
              if(res) this.$router.go()

          })
          .catch(error=> {
            this.$toasted.show(error.response.data.url, {
              duration: 1000,
              'type': 'error'
            })
          })
    },
  }
}
</script>
<style>
.main_card{
  margin-top: 50px;
}
.form-inline{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.custom-select{
  height: 30px;
  margin: 20px;
}
.media{
  margin-top: 80px;
}
.file_label{
  width: 200px;
}
</style>