<template>
  <div>

    <Navbar></Navbar>
    <div class="container-fluid p-0">
      <div class="row">
        <Side_menu></Side_menu>
        <div class="col">
          <div class="buttons_create">
            <div>
              <h3 class="font-weight-bold mb-3">Категории документов</h3>
            </div>
          </div>
            <div class=" main_card">

              <b-card no-body>
                <div class="row">
                  <div class="col-md-12">
                    <b-tabs card>
                      <b-tab title="Uzbek lotin" active >

                        <b-card-text>
                          <form @submit.prevent="put_uzl()">
                            <div class="row">
                              <div class="col-md-12  mb-3">
                                <div>
                                  <label for="first_name">Title</label>
                                  <input class="form-control" id="first_namee" v-model="title" type="text"  required>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                            </div>
                          </form>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Rus" >
                        <b-card-text>
                          <form @submit.prevent="post_ru()">
                            <div class="row">
                              <div class="col-md-12  mb-3">
                                <div>
                                  <label for="first_name">Title</label>
                                  <input class="form-control" id="first_namee" v-model="title" type="text"  required>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                            </div>
                          </form>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Uzbek kyrl">

                        <b-card-text>
                          <form @submit.prevent="put_uzk()">
                            <div class="row">
                              <div class="col-md-12  mb-3">
                                <div>
                                  <label for="first_name">Title</label>
                                  <input class="form-control" id="first_namee" v-model="title" type="text"  required>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                            </div>
                          </form>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="English">

                        <b-card-text>
                          <form @submit.prevent="put_en()">
                            <div class="row">
                              <div class="col-md-12  mb-3">
                                <div>
                                  <label for="first_name">Title</label>
                                  <input class="form-control" id="first_namee" v-model="title" type="text"  required>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save all</button>
                            </div>
                          </form>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </b-card>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Side_menu from '../../components/Side_menu'
import Navbar from "../../components/Navbar";
export default {
  components: {
    Navbar,
    Side_menu
  },
  data(){
    return{
      id:'',
      title:''
    }
  },
  methods:{
    post_ru(){
      this.$http.put('/api/heritage-category/'+this.id+'/',
          {
            "title":this.title
          },
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('m_token'),
              'Accept-Language':'ru'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
          })
        .catch(()=> {
          this.$toasted.show("Errors", {
            duration: 1000,
            'type': 'danger'
          })
        })
    },
    put_uzl(){

      this.$http.post('/api/heritage-category/',
          {
            "title":this.title
          },
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('m_token'),
              'Accept-Language':'uz-latn'
            }
          })

          .then(res=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.id=res.data.id
            this.title=''
          })
          .catch(()=> {
            this.$toasted.show("Errors", {
              duration: 1000,
              'type': 'danger'
            })
          })
    },
    put_uzk(){

      this.$http.put('/api/heritage-category/'+this.id+'/',
          {
            "title":this.title
          },
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('m_token'),
              'Accept-Language':'uz-cyrl'
            }
          })

          .then(()=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.title=''
          })
          .catch(()=> {
            this.$toasted.show("Errors", {
              duration: 1000,
              'type': 'danger'
            })
          })
    },
    put_en(){
      this.$http.put('/api/heritage-category/'+this.id+'/',
          {
            "title":this.title
          },
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('m_token'),
              'Accept-Language':'en'
            }
          })

          .then(()=>{
            this.$toasted.show("Create data success",{
              duration: 1000,
              'type':'info'

            })
            this.title=''
          })
          .catch(()=> {
            this.$toasted.show("Errors", {
              duration: 1000,
              'type': 'danger'
            })
          })
    }
  }
}
</script>
<style>
.main_card{
  margin-top: 50px;
}
.form-inline{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.custom-select{
  height: 30px;
  margin: 20px;
}
.media{
  margin-top: 80px;
}
.file_label{
  width: 200px;
}
</style>
